<template>
  <div class="estate_page relative noto">
    <v-row class="relative-center w1300 f-09">
      <v-col cols="12" xl="8" lg="8" md="8" sm="12">
        <v-carousel hide-delimiters v-model="model" class="carousel relative">
          <v-carousel-item v-for="(img, i) in imgs" :key="i">
            <img :src="img" class="absolute_xy" />
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="4" sm="12">
        <div
          class="mini_img ma-3 flex-center float-left border pointer"
          v-for="(img, i) in imgs"
          :key="i"
          @click="model = i"
          :class="{ 'border-orange': i == model }"
        >
          <img :src="img" />
        </div>
        <div class="clear" />
      </v-col>
      <v-col cols="12" class="text-left ml-1 my-2">
        <div class="f-11">{{ estate["th4"] }}</div>
        <div class="f-14 bold f-red mb-10">
          <span v-if="estate['th16']">
            価格&nbsp;&nbsp;{{ Number(estate["th16"]).toLocaleString() }}
          </span>
          <span v-if="estate['th17']">
            賃料&nbsp;&nbsp;{{ Number(estate["th17"]).toLocaleString() }}
          </span>
          万円
        </div>

        <div class="my-2" />
        <div v-text="th[9]" class="content_div float-left bold f-11" />
        <div class="float-left f-11" v-html="estate['th10']" />
        <div class="clear" />
        <div v-if="estate['th36'] != null">
          <div class="my-3" />
          <div v-text="th[35]" class="content_div float-left bold f-11" />
          <div class="float-left f-11" v-html="estate['th36'] + ' m&sup2;'" />
        </div>
        <div class="clear" />
        <div v-if="estate['th37'] != null">
          <div class="my-3" />
          <div v-text="th[36]" class="content_div float-left bold f-11" />
          <div class="float-left f-11" v-html="estate['th37'] + ' m&sup2;'" />
        </div>
        <div class="clear" />
        <div v-if="estate['th39'] != null">
          <div class="my-3" />
          <div v-text="th[38]" class="content_div float-left bold f-11" />
          <div class="float-left f-11" v-html="estate['th39'] + ' m&sup2;'" />
        </div>
        <div class="clear" />
        <div v-if="estate['th32'] != null">
          <div class="my-3" />
          <div class="content_div float-left bold f-11">間取り</div>
          <div class="float-left f-11" v-html="estate['th32']" />
        </div>
        <div class="clear" />
        <div v-if="estate['th35'] != null">
          <div class="my-3" />
          <div v-text="th[34]" class="content_div float-left bold f-11" />
          <div class="float-left f-11" v-html="formatDate2(estate['th35'])" />
        </div>
        <div class="clear" />
        <div v-if="estate['th48'] != null">
          <div class="my-3" />
          <div v-text="th[47]" class="content_div float-left bold f-11" />
          <div class="float-left f-11" v-html="estate['th48']" />
        </div>
        <div class="clear" />
        <div v-if="estate['th74'] != null">
          <div class="my-3" />
          <div v-text="th[73]" class="content_div float-left bold f-11" />
          <div class="float-left f-11" v-html="estate['th74']" />
        </div>
        <div class="clear" />
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12">
        <table class="detail_table" v-if="!user">
          <tr
            v-for="(t, i) in th"
            :key="i"
            v-show="
              (i < 30) & (estate['th' + (i + 1)] != null) &&
              i != 0 &&
              i != 1 &&
              i != 2 &&
              i != 4 &&
              i != 5 &&
              i != 6 &&
              i != 8
            "
          >
            <th v-text="t" />
            <td
              v-html="
                i == 15 || i == 16
                  ? estate['th' + (i + 1)] + '万円'
                  : i == 7
                  ? formatDate(estate['th' + (i + 1)])
                  : estate['th' + (i + 1)]
              "
            />
          </tr>
        </table>
        <table class="detail_table" v-if="user">
          <tr
            v-for="(t, i) in th"
            :key="i"
            v-show="i < 30 && estate['th' + (i + 1)] != null"
          >
            <th v-text="t" />
            <td
              v-html="
                i == 15 || i == 16
                  ? estate['th' + (i + 1)] + '万円'
                  : i == 7
                  ? formatDate(estate['th' + (i + 1)])
                  : estate['th' + (i + 1)]
              "
            />
          </tr>
        </table>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12">
        <table class="detail_table">
          <tr
            v-for="(t, i) in th"
            :key="i"
            v-show="i >= 30 && i < 89 && estate['th' + (i + 1)] != null"
          >
            <th v-text="i == 35 && type == 'rent' ? '使用面積' : t" />
            <td
              v-html="
                i == 35 || i == 36 || i == 38
                  ? estate['th' + (i + 1)] + ' m&sup2;'
                  : i == 34
                  ? formatDate2(estate['th' + (i + 1)])
                  : estate['th' + (i + 1)]
              "
            />
          </tr>
          <tr>
            <th>取引態様</th>
            <td>仲介</td>
          </tr>
        </table>
      </v-col>
      <v-col cols="12">
        <table class="detail_table my-10">
          <tr
            v-for="(t, i) in th"
            :key="i"
            v-show="i >= 89 && i < 96 && estate['th' + (i + 1)] != null"
            :class="{ nbr: i == 106 }"
          >
            <th v-text="t" />
            <td v-html="estate['th' + (i + 1)]" />
          </tr>
        </table>
      </v-col>
    </v-row>
    <Contact :Num="estate['th6']" />
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Vue from "vue";
Vue.prototype.moment = moment;

import Contact from "@/components/parts/bukenContact.vue";

export default {
  data() {
    return {
      estate: "",
      th: [
        "取引状況",
        "取引状況の補足",
        "建築及び維持保全に関する書類",
        "物件種別",
        "物件種目",
        "物件番号",
        "レインズ物件番号",
        "情報更新日",
        "登録満了日",
        "所在地",
        "建物名",
        "物件名",
        "沿線・駅・交通",
        "小学校区",
        "中学校区",
        "価格",
        "賃料",
        "敷金",
        "礼金",
        "保証金償却",
        "家賃保証",
        "精算方式",
        "共益費",
        "雑費",
        "管理費",
        "積立金",
        "その他一時金",
        "その他費用",
        "施設費用",
        "保険",
        "入居可能時期",
        "間取り部屋数",
        "間取内訳",
        "サービスルーム数",
        "築年月",
        "専有面積",
        "土地面積",
        "私道面積",
        "建物面積",
        "建物部分面積",
        "バルコニー",
        "ルーフバルコニー面積",
        "テラス面積",
        "専用庭面積",
        "階建・階",
        "階建",
        "建物工法",
        "建物構造",
        "総戸数",
        "管理会社",
        "管理形態",
        "管理方式",
        "施工会社",
        "建築面積",
        "延面積",
        "建築確認番号",
        "最適用途",
        "建物延面積",
        "出店業種条件等",
        "駐車場",
        "駐車場敷金",
        "駐車場形式・状況",
        "駐車場備考",
        "契約期間",
        "更新料",
        "引渡し可能時期",
        "現況",
        "土地権利",
        "土地形状",
        "敷地延長",
        "セットバック",
        "都市計画",
        "用途地域",
        "建・容率",
        "再建築",
        "国土法",
        "法令制限等",
        "地目",
        "地勢",
        "傾斜地面積",
        "地域地区",
        "賃貸区分",
        "建築確認・確認番号",
        "借地代",
        "借地期間",
        "保証金",
        "権利金",
        "条件等",
        "接道状況詳細",
        "設備",
        "特記事項",
        "分譲概要",
        "リフォーム1",
        "リフォーム2",
        "リフォーム3",
        "備考",
        "備考(ふれんず専用)",
        "備考(業者間)",
        "広告転載区分",
        "取引態様",
        "報酬形態",
        "手数料負担",
        "手数料配分",
        "特優貸",
        "担当者",
        "担当者連絡先",
        "登録会員",
      ],
      imgs: "",
      model: 0,
      user: false,
      type: "",
    };
  },
  components: {
    Contact,
  },
  async mounted() {
    let num = 0;
    if (this.$route.fullPath.indexOf("buy") > -1) {
      this.type = "buy";
      num = 4;
    } else {
      this.type = "rent";
      num = 5;
    }
    const data = {
      id: this.$route.params.id,
      type: this.type,
    };
    const response = await axios.post("/estate/load", data);
    this.estate = response.data.estate;
    let imgs = [];
    let i = 0;
    let a = 1;

    for (let key in response.data.imgs) {
      if (response.data.imgs[key] != null && a > num) {
        imgs[i] = response.data.imgs[key];
        i++;
      }
      a++;
    }
    a = 1;
    for (let key in response.data.imgs) {
      if (response.data.imgs[key] != null && a < num + 1) {
        imgs[i] = response.data.imgs[key];
        i++;
      }
      a++;
    }
    imgs = [...new Set(imgs)];
    this.imgs = imgs;

    this.user = response.data.user;
  },

  methods: {
    formatDate(date) {
      const newDate = new Date(date);
      const year = newDate.getFullYear();
      const month = newDate.getMonth() + 1;
      const day = newDate.getDate();
      return `${year}年${month}月${day}日`;
    },

    formatDate2(date) {
      const newDate = new Date(date);
      const year = newDate.getFullYear();
      const month = newDate.getMonth() + 1;
      return `${year}年${month}月`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/estate/detail.scss";
@import "@/assets/sass/estate/index.scss";
</style>

